import { render, staticRenderFns } from "./HomeScreenDialog.vue?vue&type=template&id=a2061e1a&scoped=true&"
import script from "./HomeScreenDialog.vue?vue&type=script&lang=js&"
export * from "./HomeScreenDialog.vue?vue&type=script&lang=js&"
import style0 from "./HomeScreenDialog.vue?vue&type=style&index=0&id=a2061e1a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2061e1a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginDialog2: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/LoginDialog2.vue').default})
