//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
  },
  name: "HomeScreenDialog",
  data() {
    return {
      isShowDialog: false,
      codeInfo: {},
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    ...mapGetters("user", ["isLogin"]),
    ...mapGetters("cart", ["cartTotal"]),
  },
  components: {
    // 组件的引用
  },
  methods: {
    ...mapActions("cart", ["getCartList"]),
    // 方法
    changeDialog() {
      this.isShowDialog = !this.isShowDialog;
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-28 09:25:56
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: //领取 优惠券
     * @return {*}
     * @Description: Do not Description
     */
    async handlerCoupon() {
      if (this.isLogin) {
        try {
          let res = await this.$getScreenCoupon();
          this.changeDialog();
          if (res.code == 200000) {
            if (this.cartTotal > 0) {
              this.$router.push({ path: `/cart` });
            }

            this.$cookies.set("addScreen", 1, {
              maxAge: 60 * 60 * 24 * 120,
              path: "/",
            });
          }
        } catch (error) {
          throw new Error("领取优惠券" + error);
        }
      } else {
        // this.$poplogin();
        this.$refs.loginDialog.changeDialog();
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-03 10:58:27
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 获取优惠券信息
     * @return {*}
     * @Description: Do not Description
     */
    async getScreenCouponInfo() {
      try {
        let res = await this.$getScreenCouponInfo();
        if (res.code == 200000) {
          this.codeInfo = res.data.salesRule;
        }
      } catch (error) {
        throw new Error("获取优惠券信息" + error);
      }
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-03 10:58:43
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 登录成功的回调
     * @return {*}
     * @Description: Do not Description
     */
    loginSuccessHanlder() {
      this.getCartList();
    },
  },
  mounted() {
    // 页面进入时加载内容
    this.getScreenCouponInfo();
  },
  watch: {
    // 监测变化
  },
};
